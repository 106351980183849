<template>
    <router-view></router-view>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {
 
        }
    }
</script>
<style scoped lang="less">

</style>