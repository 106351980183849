import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reqUrl: "http://jump.eqsfood.com.cn/api/fxdp2/",
    picUrl: "http://jump.eqsfood.com.cn",

    token: "",
    userInfo: null,
  },
  mutations: {
    SET_TOKEN: (state, v) => {
      state.token = v
    },
    SET_USERINFO: (state, v) => {
      state.userInfo = v
    }
  },
  actions: {

  },
  modules: {},
});
