import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// 解决vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import store from '@/store/index'
import zxHttp from '@/axios/zx'
import { Message } from "element-ui";

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: () => import("@/views/layout.vue"),
      redirect: "/index",
      children: [
        // {
        //   path: "/home",
        //   name: "home",
        //   component: () => import("@/views/home.vue"),
        // },
        {
          path: "/index",
          name: "index",
          component: () => import("@/views/index.vue"),
          redirect: "/page1",
          children: [
            {
              path: "/test",
              name: "test",
              component: () => import("@/views/test/index.vue"),
            },
            {
              path: "/page1",
              name: "page1",
              meta: {
                needToken: true,
              },
              component: () => import("@/views/page1/index.vue"),
            },
            {
              path: "/page2",
              name: "page2",
              meta: {
                needToken: true,
              },
              component: () => import("@/views/page2/index.vue"),
            },
            {
              path: "/page3",
              name: "page3",
              meta: {
                needToken: true,
              },
              component: () => import("@/views/page3/index.vue"),
            },
            {
              path: "/page3-1",
              name: "page3-1",
              component: () => import("@/views/page3-1/index.vue"),
            },
            {
              path: "/page4",
              name: "page4",
              meta: {
                needToken: true,
              },
              component: () => import("@/views/page4/index.vue"),
            }
          ]
        }
      ]
    },
    {
      path: "/dp_zhgy",
      component: () => import("@/views/layout.vue"),
      name: "dp_zhgy",
      redirect: {
        name: "zhwl"
      },
      children: [
        {
          path: "/dp_zhgy/zhwl",
          name: "zhwl",
          component: () => import("@/views/zhwl.vue"),
        },
      ]
    }
  ]
});


router.beforeEach(async (to, from, next) => {
  // console.log(to);
  // let needToken = to.meta.needToken

  // let token = store.state.token || to.query.token

  // if (token) store.commit("SET_TOKEN", token)

  // if (!token && process.env.NODE_ENV == 'development') {
  //   let { userinfo } = await zxHttp.request({
  //     url: "user/login",
  //     method: "POST",
  //     data: {
  //       account: 'hbadmin',
  //       password: "hbadmin@2023"
  //     }
  //   })
  //   token = userinfo.token
  //   store.commit("SET_TOKEN", token)
  // }

  // if (needToken) {
  //   if (!token) {
  //     Message.error(`需要token`)
  //     throw '需要token'
  //   }
  //   if (!store.state.userInfo) {
  //     let userInfo = await zxHttp.request({
  //       url: "user/get_user_info",
  //       method: "POST"
  //     })
  //     store.commit("SET_USERINFO", userInfo)
  //   }
  // }
  next()
})


export default router;
