import axios from 'axios'
import store from '@/store/index'
import { Message } from 'element-ui';

const http = axios.create({
    headers: {
        Authorization: `app_guid=bb165697883d8d7c;corporation_guid=`
    }
})
http.interceptors.request.use(config => {
    config.headers['token'] = store.state.token
    config.url = `https://ucenter.eqscloud.com/api/` + config.url
    return config;
}, error => {
    console.log("触发请求拦截错误");
    return Promise.reject(error);
});

http.interceptors.response.use(res => {
    return res.data.data;
}, error => {
    Message.error(error.response.data.msg)
    return Promise.reject(error);
});

export default http