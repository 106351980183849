
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// bug必须在main里引入 否则多页面地图按需引入的时候 切换页面会不渲染地图数据
import * as echarts from 'echarts'
import chinaMap from '@/assets/china.json'
echarts.registerMap('china', { geoJSON: chinaMap })

import '@wangeditor/editor/dist/css/style.css'


import '@/assets/css/global.less'
import '@/assets/iconfont/tq.css'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast);

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})


import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'O8l5kygKWYWZGV6qw9MBisBz8lqlOVtA' })

import vuescroll from "vuescroll";
Vue.use(vuescroll, {
  ops: {
    // mode: 'native',
    bar: {
      // showDelay: 500,
      // onlyShowBarOnScroll: false,//是否只有滚动的时候才显示滚动条
      keepShow: true,
      background: 'rgba(14, 167, 255, 0.2)',
      // opacity: 0.2,
      // hoverStyle: false,
      // specifyBorderRadius: false,
      // minSize: false,
      // size: '6px',
      // disable: false,
      // 'overflow-x': 'hidden',
    }
  }, // 在这里设置全局默认配置
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});

import scroll from 'vue-seamless-scroll'
Vue.use(scroll, { componentName: 'scroll-seamless' })

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
